export const maskStreetNumber = [/\d/, /\d/, /\d/, /\d/]

/*0 00 00 0A 000 000 00*/
export const maskNss = [
  /\d/,
  " ",
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\w/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
]

export const maskPostalCode = [/\d/, /\d/, /\d/, /\d/, /\d/]

export const maskPhoneNumber = [
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/
]

export const maskCompletePhoneNumber = [
  /[\+]/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  " ",
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/
]